:root {
  /* Colors */
  --white: #ffffff;
  --dark: #042326;
  --secondary: #246b73;
  --primary: #238c6e;
  --accent: #3bd952;
  --dark-green: #238c51;

  /* Terminal Styles */
  --terminal--background: var(--dark);
  --terminal--text: var(--primary);
  --terminal--input-text: var(--accent);
  --terminal--shadow: rgba(0, 0, 0, 0.205);
}
.white {
  color: var(--white);
}
.p-text {
  color: var(--terminal--primary);
}
.d-text {
  color: var(--dark-green);
}

.s-text {
  color: var(--terminal--input-text);
}

.container {
  margin: 0 150px;
}

main {
  width: 90%;
  margin: 0 auto;
}

@media only screen and (min-width: 600px) {
  main {
    width: 70%;
  }
  .mobile-warning {
    display: none;
  }
}
