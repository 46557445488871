nav {
  margin: 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.header--accent {
  color: var(--secondary);
}

nav h1,
nav h2,
nav h3 {
  font-family: "Source Sans Pro", sans-serif;
  color: var(--dark);
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

a {
  color: black;
  text-decoration: none;
}

li a:hover {
  text-decoration: underline;
}

nav ul li {
  padding-left: 25px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  nav {
    flex-direction: row;
  }
}

/* Large devices (laptops/desktops, 992px and up)
@media only screen and (min-width: 992px) {...} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {...} */
