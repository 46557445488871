.terminal--container {
  margin: 40px 0;
  background-color: var(--terminal--background);
  padding: 20px 30px;
  border: none;
  border-radius: 10px;
  min-height: 550px;
  box-shadow: 0 3px 6px var(--terminal--shadow);
}

.terminal--container code {
  color: var(--terminal--text);
}

.terminal--subcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--dark-green);
  font-size: 10px;
}

.terminal--container h3 {
  color: white;
}

.terminal--subcontainer form {
  width: 50%;
}
.terminal--subcontainer form input[type="text"] {
  width: 100%;
}

.code--input {
  margin-left: 5px;
  width: 100%;
  color: var(--terminal--input-text);
  background-color: var(--grey);
  border: none;
}

.terminal--code {
  margin-top: 15px;
}

.terminal--code a {
  color: var(--terminal--text);
  text-decoration: underline;
}

.terminal--container form input[type="submit"] {
  width: 1px;
  height: 1px;
}

input.code--input:focus,
input.code--input:active,
input.code--input:focus-visible {
  border: none;
  outline: none;
}

code,
.terminal--container input {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.terminal--container input {
  font-size: 16px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .terminal--container {
    min-height: 550px;
  }
  .terminal--subcontainer {
    font-size: 16px;
  }
}

/* Large devices (laptops/desktops, 992px and up)
@media only screen and (min-width: 992px) {...} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {...} */
